.dialog{
    
    background:lightskyblue ;
    height:100vh;
    width:100vw;
    
    
  
}

.dialog *{
    display: flex;
    justify-content: center;
    /* transform: translateY(10rem); */


}

.construction{
    height:50vh;
    width:50vw;
    border-radius: 10px;
   
}

.imgContainer{
    margin-top: 20px;
}




