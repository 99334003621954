

.collapseBtn{
    margin: 5px ;
    background-color:#5c7cdb;
   
   
   border :1.5px white solid;
    
    
}


li{
    
    margin-top: 20px;
    color: #5c7cdb;
    font-weight: 500;
    font-size: 18px;
    
}
li:hover{
   transform: translateY(-10px);
  
}




li box-icon{
    margin-right: 10px;
    margin-left: 5px;
    transform: translateY(5px);
}
.nav-item{
    display: flex;
    align-items: center;
    margin-left: 15px;
    color: #5c7cdb;
}
.nav-item:hover {
    border-radius: 3px;
     /* background-color: rgba(232, 232, 232, 0.719);  */
    background-color: rgb(197, 208, 219);
    cursor:pointer;
    
}

.item{
    display: flex;
    align-items: center;
    margin-left: 15px;
    color: #5c7cdb;
   
}
.item:hover{
    border-radius: 3px;
     background-color: rgba(232, 232, 232, 0.719);
     
}


.sidebarContainer{
    height:100%;
    width: 100%;
    /* background-color: rgba(239, 235, 235, 0.519); */
      background-color: rgba(242, 237, 237, 0.519); 
  
 }

 .offcanvas-title{
     /* color:royalblue; */
     color:#5c7cdb

 }

 #offcanvasExampleLabel{
     padding-left: 40px;
 }

.logout{
    transform: translateX(-5px);
}
 


