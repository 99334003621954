
.homeBodyDiv{
background-color: rgb(251, 253, 255);
display: flex;
justify-content: center;
align-items: center;
gap: 4rem;
flex-wrap: wrap;
margin-top: 7rem;

}

.showcase{
   margin-top: 20px;
   text-align: center;
   max-width: 50%;
   
}
.showcase *{
    margin:2rem;
}

.trackingImgDiv{
display: flex;
justify-content: center;

}

.trackTicketImg{
    max-width: 60%;
  
}

@media only screen and (max-width: 1440px) {
    .trackTicketImg{
        max-width: 60%;
        transform:translate(-60px);
      
    }
}

@media only screen and (max-width: 760px) {
    .trackTicketImg{
        max-width: 60%;
        transform:translate(-10px);
      
    }

    .homeBodyDiv{
        margin-top: 2rem;
    }

}



 


