header{
    text-align: center;
   
}
header *{
    font-weight: 400;
    margin: 20px;
}

.userimage{
    max-height:150px;
    max-width: 150px;
    border-radius: 100%;
}

.container *{
    text-align: center;
    margin: 15px;
    font-size: 20px;
    font-weight: 400;
    
}

.roles{
    display: flex;
    justify-content: center;
    align-items: center;
 

}
.jumbotron h1{
    font-size: 34px;
    font-weight: 400;
}