loginContainer{
    display:"flex";
    justify-content:"center";

    align-items:"center";
    height: 100%;
    width: 100vw;
   
   
}

.card{
    max-width: 500px;
    max-width: 500px;
    padding: 40px 40px;
    margin: 0 auto;
    margin-top: 7rem;
  background-color: rgb(225, 240, 255);
  border-radius: 6px;
}
 .card *{
margin: 0 auto;
text-align: center;
margin-top: 5px;
}

.welcome{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    font-weight: 300;
    font-size: 30px;
}

.options{
    margin-top: 20px;
   
}

.home{
    margin-left: 30px;
    text-decoration: none;
}

.register{
    text-decoration: none;
}

.loginText{
    margin-bottom: 10px;
}