
.tableContainer{
    
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    /* border: 1px black solid; */
    width: 100%;     
}

.container{
    display: flex;
    flex-direction: column;
   
}



.userHeader span{
    display: flex;
    justify-content: center;
    font-size: 34px;
    font-weight: 400;
    color:royalblue
}

.viewButton {
    margin:5px;
}

.deleteButton{
margin: 5px;
}


.addUserbtn{
    text-decoration: none;
}

.addUserbtn:hover{
    text-decoration: underline;
}

.footerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}

