

.tableContainer{
    
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    /* border: 1px black solid; */
    width: 100%;     

}

.container{
    display: flex;
    flex-direction: column;
    
}



#projectHeader span{
    display: flex;
    justify-content: center;
    font-size: 34px;
    font-weight: 400;
    color: royalblue;

}

.viewButton {
    margin:5px;
    

}

.deleteButton{
margin: 5px;
color: red;
text-decoration: none;
}


.addProject{
    border:none;
    background-color: royalblue;
    border:1px lightgrey;
    height: 80%;
    
}

.addProjectbtn{
    text-decoration: none;
}

.addProjectbtn:hover{
   text-decoration: underline;
}


.footerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}



